<template>
    <div>
        <router-view />
        <audio id="audio" style="display:none" :muted="muted" :src="tishi_2" />
    </div>
</template>

<script>
import { setInterval } from 'timers'
export default {
    data(){
        return{
            muted: true,
            tishi_1: require("../assets/tishiy_1.mp3"),
            tishi_2: require("../assets/tishiy_2.mp3"),
            websocketTime: null,
            sendForm: {},
            lockReconnect: false,//是否真正建立连接
            timeout: 1*1000,//30秒一次心跳
            timeoutObj: null,//心跳心跳倒计时
            serverTimeoutObj: null,//心跳倒计时
            timeoutnum: null,//断开 重连倒计时
        }
    },
    created(){
        this.sendForm = JSON.parse(sessionStorage['sendForm'])
        this.initWebSocket(JSON.parse(sessionStorage['sendForm']))
        if(JSON.parse(sessionStorage['sendForm']).sex == 'women'){
            this.giftLi("men")
        }else{
            this.giftLi("women")
        }
    },
    computed: {
        // 接收数据
        setContent(){
            return this.$store.state.setContent
        }
    },
    watch: {
        setContent(data){
            this.websock.send(data)
        }
    },
    methods: {
        // 喜欢
        giftLi(sex){
            this.$isAxios.axiosGet(this.$isApi.userLikeShowAll,{sex:sex,pageNo:1,pageSize:1000},(res)=>{
                if(res.data.message == 'success'){
                    this.$store.state.loveGift = res.data.result
                }
            })
        },
        //初始化weosocket
        initWebSocket(e){
            this.websock = new WebSocket(this.$isApi.websocket+"/"+e.userId+'_pc')
            // 数据接收
            this.websock.onmessage = (get)=>{
                if(get.data == 'ok'){
                    this.$store.state.socketYes = true
                    this.reset()
                }else if(get.data == 'refresh'){
                    this.$isAxios.axiosGet(this.$isApi.showUserInfo,{userId:JSON.parse(sessionStorage['sendForm']).userId},(res)=>{
                        if(res.data.result){
                            sessionStorage.sendForm = JSON.stringify(res.data.result)
                            setTimeout(()=>{
                                location.reload()
                            },1000)
                        }
                    })
                }else if(JSON.parse(get.data).type == 'removeChat'){
                    this.$message({
                        message: JSON.parse(get.data).sendName + this.$i18n.t('login.deleteUserChat'),
                        type: 'warning'
                    })
                    this.$isChat.chatList(JSON.parse(sessionStorage['sendForm']),(res)=>{})
                }else if(JSON.parse(get.data).type == 'addChat'){
                    this.$message({
                        message: JSON.parse(get.data).sendName + this.$i18n.t('login.addUserChat'),
                        type: 'success'
                    })
                }else if(JSON.parse(get.data).socketSendId){
                    this.$store.commit('getSocke',JSON.parse(get.data))
                    if(!this.$store.state.sayShow){
                        this.$store.state.sayNumber++
                    }else{
                        this.$store.state.sayNumber = 0
                    }
                }else{
                    this.$store.state.socketYes = false
                }
            },
            this.websock.onopen = ()=>{
                this.$message({
                    message: this.$i18n.t('login.lianjiechenggong'),
                    type: 'success'
                })
                this.$isChat.chatList(JSON.parse(sessionStorage['sendForm']),(res)=>{
                    this.websock.send(JSON.stringify({content: "heartCheck"}))
                })
            }
            this.websock.onerror = this.websocketonerror
            this.websock.onclose = this.websocketclose
        },
        //重新连接
        reconnect() {
            if(this.lockReconnect) {
                return
            }
            this.lockReconnect = true
            //没连接上会一直重连，设置延迟避免请求过多
            this.timeoutnum && clearTimeout(this.timeoutnum);
            this.timeoutnum = setTimeout(() => {
                //新连接
                this.initWebSocket()
                this.lockReconnect = false
            },1000)
        },
        //重置心跳
        reset(){
            //清除时间
            clearTimeout(this.timeoutObj)
            clearTimeout(this.serverTimeoutObj)
            //重启心跳
            this.start()
        },
        //开启心跳
        start(){
            this.timeoutObj && clearTimeout(this.timeoutObj)
            this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj)
            this.timeoutObj = setTimeout(() => {
                //这里发送一个心跳，后端收到后，返回一个心跳消息，
                if (this.websock.readyState == 1) {
                    //如果连接正常
                    this.websock.send(JSON.stringify({content: "heartCheck"}))
                }else{
                    //否则重连
                    this.reconnect()
                }
                //超时关闭
                this.serverTimeoutObj = setTimeout(() => {
                    this.websocketclose()
                }, this.timeout)
            }, this.timeout)
        },
        //连接建立失败重连
        websocketonerror(){
            console.log("error")
            this.reconnect()
        },
        //关闭
        websocketclose(e){
            console.log("close")
            this.reconnect()
        }
    }
}
</script>